import { useState, useEffect } from 'react';

const useIncrement= (initialProgress, targetProgress, delay = 10) => {
    const [progress, setProgress] = useState(initialProgress);

    useEffect(() => {
        const interval = setInterval(() => {
            if (progress === targetProgress) {
                clearInterval(interval);
            } else if (progress < targetProgress) {
                setProgress(prevProgress => prevProgress + 1);
            } else if (progress > targetProgress) {
                setProgress(prevProgress => prevProgress - 1);
            }
        }, delay);

        return () => clearInterval(interval);
    }, [progress, targetProgress, delay]);

    return progress;
};

export default useIncrement;
