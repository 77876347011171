import React, { useEffect, useState, useRef, memo } from 'react'
import Token from '../Token'
import { generateRandomPoints } from '../../utils/coords'

const TokenView = ({ tokens, totalVotes, openModalCb, currentTokenCb, parentRef }) => {
    const THROTTLE_DELAY = 1200; // Adjust delay as needed
    const UPPER_SCALE_LIMIT = 3
    const LOWER_SCALE_LIMIT = 0.5
    const BUCKET_COUNT = 30

    const sortedTokens = [...tokens].sort((a, b) => (a.text > b.text) ? 1 : -1)
    const scaleThresholds = new Map(Array.from(new Array(BUCKET_COUNT),
        (x, i) => i * ((UPPER_SCALE_LIMIT - LOWER_SCALE_LIMIT) / BUCKET_COUNT) + LOWER_SCALE_LIMIT)
        .map((x, i) => [i, x]));

    const [initState, setInitState] = useState([])
    const [scaleTokens, setScaleTokens] = useState([])

    let lastExecutionTime = useRef(0);
    const isTokensFirstLoaded = useRef(false)

    useEffect(() => {
        const width = parentRef.current.clientWidth
        const height = parentRef.current.clientHeight - parentRef.current.offsetTop * 1.5

        const offsetHeight = width / 2
        const offsetWidth = height / 2
        const offset = { x: offsetHeight, y: offsetWidth }

        const radius = Math.sqrt(Math.pow((offsetHeight), 2) + Math.pow((offsetWidth), 2) / 2) / 2
        const pos = generateRandomPoints(radius, 25, offset, 50)
        const newState = pos.map((pos) => ({ ...pos, spawnDelay: Math.floor(Math.random() * 3000) }))
        setInitState(newState)
    }, [])


    useEffect(() => {
        if (!isTokensFirstLoaded.current) {
            if (sortedTokens.length > 1) {
                isTokensFirstLoaded.current = true
                setScaleTokens(genTokenScale(sortedTokens))
            }
        }
        const now = Date.now();
        if (now - lastExecutionTime.current >= THROTTLE_DELAY) {
            setScaleTokens(genTokenScale(sortedTokens));
            lastExecutionTime.current = now;
        }
    }, [tokens]);

    function genTokenScale(sortedTokens) {
        if (tokens.length <= 2) return
        const SCALE_FACTOR = 1
        const scaledTokens = tokens.map(token => {
            let totalVote = token.totalVote === 0 ? 1 : token.totalVote
            let averageVote = (totalVotes + sortedTokens.length) / sortedTokens.length;
            let voteRatio = totalVote / averageVote
            if (voteRatio > 1) {
                voteRatio /= SCALE_FACTOR
            } else {
                voteRatio *= SCALE_FACTOR
            }
            return {
                name: token.text,
                scale: Math.min(UPPER_SCALE_LIMIT, Math.max(LOWER_SCALE_LIMIT, voteRatio))
            }
        })
        return scaledTokens
    }

    const tokenComponentList = (tokens) => {
        let size = 40
        // console.log(tokens, initState)
        return tokens.map((token, index) =>
            <Token
                key={index}
                size={size}
                token={token}
                totalVotes={totalVotes}
                initState={initState[index]}
                openModal={openModalCb}
                currentToken={currentTokenCb}
                scale={scaleTokens?.find(t => t.name === token.text)?.scale ?? 0}
                scaleThresholds={scaleThresholds}
            />
        )
    }

    return (
        scaleTokens && <div>
            {sortedTokens.length > 1 && tokenComponentList(sortedTokens)}
        </div>
    )
}

export default memo(TokenView)