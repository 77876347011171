import './css/GeoGalaxy.css';
import { Cryptos } from './details/crypto';
import VotePopout from './components/vote-popout/VotePopout';
import SideBar from './components/SideBar';
import Header from './components/Header';
import React, { useEffect, useRef, useState, useCallback  } from 'react';
import TokenView from './components/views/TokenView';
import About from './components/About';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { decodeProtoVotes } from './utils/decode';
import { priceApi } from './api/priceApi';


const App = () => {
  const BUFFER_DELAY = 200;
  const parentRef = useRef(null);
  const tokenConfigList = useRef(Object.values(Cryptos).map(c => (c)));
  const tokenWsUrl =     
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
  ? 'ws://127.0.0.1:8081/cryptoVotes'
  : 'wss://coinpulse.live/cryptoVotes';

  const [tokens, setTokens] = useState([{}]);
  const [bufferTokens, setBufferTokens] = useState([{}]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [currentToken, setCurrentToken] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [tokenPrices, setTokenPrices] = useState([{}]);
  const [tokenVoteMap, setTokenVoteMap] = useState(new Map());

  const { sendMessage, lastMessage, readyState } = useWebSocket(tokenWsUrl, {
    retryOnError: true,
    shouldReconnect: () => true,
  });

  // Run when the connection state (readyState) changes
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      //do stuff
    }
  }, [readyState])

  useEffect(() => {
    decodeProtoVotes(lastMessage?.data).
      then(voteObj => {
        if (voteObj?.votes) {
          const totalVote = voteObj.votes.bearVotes + voteObj.votes.bullVotes
          tokenVoteMap.set(voteObj.name, { ...voteObj.votes, totalVote: totalVote });
          setTokenVoteMap(tokenVoteMap);
        }
      }).then(() => {
        let newTokens = Object.values(tokenConfigList.current).map(
          (tokenConfig) => tokenVoteMap.has(tokenConfig.text)
            ? {
              ...tokenConfig, ...tokenVoteMap.get(tokenConfig.text),
              prevTotalVote: tokens?.find(t => t?.text === tokenConfig?.text)?.totalVote ?? 0,
              prevBearVotes: tokens?.find(t => t?.text === tokenConfig?.text)?.bearVotes ?? 0,
              prevBullVotes: tokens?.find(t => t?.text === tokenConfig?.text)?.bullVotes ?? 0
            }
            : {}
        );
        if (!newTokens) return
        newTokens = newTokens.filter(token => Object.keys(token).length > 0);
        if (tokenConfigList.current.length === newTokens.length) {
          setBufferTokens(newTokens);
        }
      })
  }, [lastMessage, tokenConfigList, tokenVoteMap]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTokens(bufferTokens);
      setTotalVotes(bufferTokens.reduce((acc, curr) => acc + curr.totalVote, 0));
    }, BUFFER_DELAY);
    return () => clearInterval(interval);
  }, [bufferTokens]);

  const currentTokenCb = useCallback((token) => {
    setCurrentToken(token);
  }, [setCurrentToken]);

  const aboutModalCb = useCallback((state) => {
    setIsAboutModalOpen(state.open);
  }, [setIsAboutModalOpen]);

  const openModalCb = useCallback((state) => {
    setIsModalOpen(state.open);
  }, [setIsModalOpen]);

  useEffect(() => {
    priceApi.getPrices()
      .then(data => {
        if (data) {
          setTokenPrices(data)
        }
      })
  }, [])

  return (
    <div className="h-screen grid grid-rows-[auto_1fr] grid-cols-[auto_1fr]">

      <div className="z-30 col-span-2">
        <Header
          openModal={openModalCb}
          aboutModal={aboutModalCb}
          currentToken={currentTokenCb}
        />
      </div>

      <div className="h-full">
        <SideBar
          tokens={tokens}
          totalVotes={totalVotes}
          tokenPrices={tokenPrices}
        />
      </div>

      <main ref={parentRef} className="z-10 h-full space-body space stars1">
        <TokenView
          tokens={tokens}
          totalVotes={totalVotes}
          parentRef={parentRef}
          openModalCb={openModalCb}
          currentTokenCb={currentTokenCb}
        />
      </main>

      <VotePopout
        isOpen={isModalOpen}
        openModalCb={openModalCb}
        token={currentToken}
      />

      <About
        open={isAboutModalOpen}
        aboutModal={aboutModalCb}
      />
    </div>
  );
};

export default App;