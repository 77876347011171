import React, { useEffect, useState, memo } from 'react'
import { Sidebar, Spinner } from "flowbite-react";
import StatusView from './status-icon/StatusView';
import { useTransition, animated } from '@react-spring/web'

const SideBar = (props) => {
    const height = 196
    const [rank, setRank] = useState([])
    const tokens = props.tokens.sort((a, b) => (b.bullVotes + b.bearVotes) - (a.bullVotes + a.bearVotes));

    useEffect(() => {
        let newRank = tokens
            .map((token, index) => ({ name: token.text, rank: index + 1 }))
            .filter(token => token.name !== undefined)
        setRank(newRank)
    }, [props.totalVotes, tokens])

    const transitions = useTransition(
        rank.map((data, i) => ({ ...data, y: (i * height) - 32 })),
        {
            key: item => item.name,
            from: { height: 0, opacity: 1 },
            leave: { height: 0, opacity: 0 },
            enter: ({ y }) => ({ y, opacity: 1 }),
            update: ({ y }) => ({ y }),
        }
    )

    return (
        <Sidebar className='w-64 pb-16'>
            <Sidebar.ItemGroup className="h-full">
                {rank.length <= 0 ? (
                    <div className='h-full flex items-center justify-center'>
                        <Spinner size="xl" />
                    </div>
                ) : transitions((style, item, t, index) => (
                    <animated.div style={{ zIndex: rank.length - index, ...style }}>
                        <div className='relative flex items-center justify-center p-4'>
                            <Sidebar.Item >
                                <StatusView
                                    key={index}
                                    totalVotes={props.totalVotes}
                                    token={tokens.find(token => token.text === item.name)}
                                    tokenPrice={props.tokenPrices.find(({ name }) => name === item.name)?.price ?? 0} 
                                />
                            </Sidebar.Item>
                        </div>
                    </animated.div>
                ))}
            </Sidebar.ItemGroup>
        </Sidebar>
    );
}

export default memo(SideBar)
