import React from 'react'

const RowItem = (props) => {
  const token = props.token
	
	const handleClick = () => {
		props.openModal({open: true})
		props.currentToken(token)
	}
  
	return (
    <a onClick={handleClick} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{token.text}</a>
  )
}

export default RowItem