import React, { useEffect } from 'react'
import RowItem from './RowItem'

const DropDown = ({ tokens, isHidden, openModal, currentToken }) => {

	const ListItems = tokens.map((token, index) =>
		<RowItem
			key={index}
			token={token}
			openModal={openModal}
			currentToken={currentToken}
		/>
	)

	return (
		<>
			<div id="dropdown" style={{ display: isHidden ? 'none' : 'block' }} className="z-30  bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700">
				<ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
					{tokens.length > 0 && ListItems}
				</ul>
			</div>
		</>
	)
}

export default DropDown