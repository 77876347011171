import React, { useEffect } from 'react'
import { Card } from 'flowbite-react'
import { FcBullish, FcBearish } from "react-icons/fc";


const VoteView = ({ token, voteHandler }) => {

    const handleUpVote = () => {
        voteHandler(token.text, "bull")
    }

    const handleDownVote = () => {
        voteHandler(token.text, "bear")
    }

    useEffect(() => {

    }, [])

    return (
        <div className="flex p-2 space-x-2 mx-auto">
            <Card className="size-56 p-6 border-0 justify-center shadow-none">
                <img src={token.backgroundImage} className="object-scale-down" />
                <h5 className='text-center text-2xl font-bold text-gray-900 break-words'>
                    {token.text}
                </h5>
            </Card>
            <Card onClick={handleUpVote} className='size-56 hover:bg-slate-100'>
                <FcBullish className="size-28 object-scale-down" />
            </Card>
            <Card onClick={handleDownVote} className='size-56 hover:bg-slate-100'>
                <FcBearish className="size-28 object-scale-down" />
            </Card>
        </div>
    )
}

export default VoteView

