import React, { useEffect, useRef, useState } from 'react'
import useIncrement from '../hooks/useIncrement'
const RadialProgressBar = (props) => {
    const progress = useIncrement(props.prevProgress, props.progress, 8);
    let color = props.isRed ? 'text-red-500' : 'text-green-500';

    return (
        <div className="relative w-40 h-40">
            <svg className="w-full h-full" viewBox="0 0 100 100">
                <circle
                    className="text-gray-200 stroke-current"
                    strokeWidth="10"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                ></circle>
                <circle
                    className={`${color} progress-ring__circle stroke-current`}
                    strokeWidth="10"
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="40"
                    fill="transparent"
                    style={{
                        strokeDasharray: 251.2,
                        strokeDashoffset: `calc(251.2 - (251.2 * ${progress}) / 100)`
                    }}
                ></circle>

                <text x="50" y="50" fontFamily="Verdana" fontSize="18" textAnchor="middle" alignmentBaseline="middle">{progress}%</text>

            </svg>
        </div>
    )
}

export default RadialProgressBar

