export function fixedNumber(num, fixed = 2) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(fixed) + 'B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(fixed) + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(fixed) + 'K';
  } else if (num >= 0.001 && num < 1) {
    return num.toFixed(3);
  }
  else if (num < 0.001 && num > 0) {
    const exponent = Math.floor(Math.log10(Math.abs(num)));
    const base = num / Math.pow(10, exponent);
    return base.toFixed(fixed) + `e${exponent}`;
  } else {
    return num.toString();  // Handles numbers less than 1 or negative values
  }
}
