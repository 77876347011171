import React from 'react';
import { Toast } from "flowbite-react";
import { HiCheck, HiExclamation, HiX } from "react-icons/hi";
import { useTransition, animated } from '@react-spring/web';
import { useEffect, useState } from 'react';

const Dialog = ({ response, isDialogVisible, dialogClose }) => {

  useEffect(() => {
    let timeout = setTimeout(() => {
      dialogClose();
    }, 1000)
    return () => {
      clearTimeout(timeout);
    };
  }, [isDialogVisible]);

  const transitions = useTransition(isDialogVisible, {
    from: { opacity: 0, x: -20, y: 100, delay: 500 },
    enter: { opacity: 1, x: -20, y: -20 },
    leave: { opacity: 0, x: -20, y: -20 },
    config: { duration: 300 },
  });

  function toastElmt(dialType, dialMessage) {
    let result;
    switch (dialType) {
      case "success":
        result = (
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheck className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">{dialMessage}</div>
            <Toast.Toggle />
          </Toast>
        );
        break;
      case "error":
        result = (
          <Toast >
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
              <HiX className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">{dialMessage}</div>
            <Toast.Toggle />
          </Toast>
        );
        break;
      case "warning":
        result = (
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200">
              <HiExclamation className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">{dialMessage}</div>
            <Toast.Toggle />
          </Toast>
        );
        break;
      default:
        break;
    }

    return result;
  }

  return (
    <>
      {transitions((style, visible) =>
        visible && (
          <animated.div style={style}>
            {toastElmt(response.type, response.message)}
          </animated.div>
        )
      )}
    </>
  );
};

export default Dialog;