import React, { useRef } from 'react'
import DropDown from './DropDown'
import { useState, useEffect } from 'react'
import { Cryptos } from '../../details/crypto'


const SearchBar = (props) => {
  const MAX_ITEMS = 10

  const tokenList = Object.values(Cryptos)
    .map(c => (c))
    .sort((a, b) => a.text.localeCompare(b.text));

  const [isDropDownHidden, setDropDownHidden] = useState(true)
  const [tokens, setTokens] = useState(tokenList.slice(0, MAX_ITEMS))

  const onFocus = (event) => {
    if (event.type === 'focus') {
      setDropDownHidden(false)
    }
    if (event.type === 'blur') {
      setTimeout(() => setDropDownHidden(true), 200)
    }
  }

  const onTyping = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const matchingTokens = tokenList.filter((token) => token.text.toLowerCase().includes(searchTerm));
    const limitedMatchingTokens = matchingTokens.slice(0, MAX_ITEMS);
    setTokens(limitedMatchingTokens);
  };

  return (
    <div className="max-w-md min-w-64 mx-auto relative">
      {/* Search bar */}
      <Search onFocus={onFocus} onTyping={onTyping} />

      {/* Dropdown */}
      {!isDropDownHidden && (
        <div className="absolute top-full left-0 w-full mt-2 z-20">
          <DropDown
            tokens={tokens}
            currentToken={props.currentToken}
            openModal={props.openModal}
          />
        </div>
      )}
    </div>
  )
}

const Search = ({ onFocus, onTyping }) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
        </svg>
      </div>
      <input onChange={onTyping} onFocus={onFocus} onBlur={onFocus} autoComplete='off' type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search your favorite crypto" />
    </div>
  )
}


export default SearchBar
