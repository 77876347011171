const protobuf = require('protobufjs');

export const decodeProtoVotes = async (blob) => {
  const root = await protobuf.load("crypto.proto");
  const reader = new FileReader();
  const cryptoVotes = root.lookupType('ProtoCryptoVotes');

  // Function to handle the FileReader onload event
  const handleFileReaderLoad = () => {
    // Ensure reader.result is an ArrayBuffer
    const voteBlob = new Uint8Array(reader.result);

    // Decode the protobuf data
    const votes = cryptoVotes.decode(voteBlob);

    // Convert to a plain object with default values
    const votesObject = cryptoVotes.toObject(votes, {
      enums: String,
      defaults: true,
      arrays: true,
      objects: true
    });
    return votesObject;
  };

  if (blob) {
    reader.onload = handleFileReaderLoad;
    reader.readAsArrayBuffer(blob);
  }
  return await new Promise((resolve) => {
    reader.onload = () => resolve(handleFileReaderLoad());
  });
};
