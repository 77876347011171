import React from 'react'
import RadialProgressBar from '../RadialProgressBar'
import { Card } from 'flowbite-react'
import { fixedNumber } from '../../utils/numFormatter'


const StatusView = ({ token, tokenPrice }) => {
    const bullVotesPercentage = Math.floor(((token?.totalVote - token?.bearVotes) / token?.totalVote) * 100) ?? 0
    const bearVotesPercentage = Math.ceil(((token?.totalVote - token?.bullVotes) / token?.totalVote) * 100) ?? 0
    const prevBullVotesPercentage = token?.prevTotalVote > 0
        ? Math.floor(((token.prevTotalVote - token.prevBearVotes) / token.prevTotalVote) * 100)
        : 0;

    const prevBearVotesPercentage = token?.prevTotalVote > 0
        ? Math.ceil(((token.prevTotalVote - token.prevBullVotes) / token.prevTotalVote) * 100)
        : 0;
    return (
        <Card className='size-48 border-2 justify-center'>
            <div className="size-36 flex items-center justify-center  bg-white">
                <div className="grid grid-cols-2 grid-rows-2 gap-2 w-4/5 h-4/5">
                    <div className="flex items-center justify-center bg-white text-white text-xl">
                        <img src={token?.backgroundImage ?? ''} className='object-scale-down' />
                    </div>
                    <div className="flex items-center justify-center bg-white text-white text-xl">
                        <RadialProgressBar text={token?.text} progress={bullVotesPercentage} prevProgress={prevBullVotesPercentage} />
                    </div>
                    <div className="flex items-center justify-center bg-white text-black text-xl">
                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"> ${fixedNumber(tokenPrice ?? 0, 1)}</div>
                    </div>
                    <div className="flex items-center justify-center bg-white text-white text-xl">
                        <RadialProgressBar text={token?.text} isRed={true} progress={bearVotesPercentage} prevProgress={prevBearVotesPercentage} />
                    </div>
                </div>
            </div>
            <div className="space-y-2 text-center text-medium font-bold text-gray-900">Total Votes : {fixedNumber(token?.totalVote)} </div>
        </Card>
    )
}

export default StatusView
