import React, { memo } from 'react'
import { Modal } from 'flowbite-react'
import { FaBitcoin, FaTwitter } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";



const About = (props) => {
	return (
		<Modal dismissible show={props.open} onClose={() => props.aboutModal({ open: false })}>
			<Modal.Header>
				About Us
			</Modal.Header>
			<Modal.Body>
				<p>
					Welcome to Coin Pulse, where we crowdsource the collective anxiety of the crypto community.
					Vote on the latest market trends, coin performances, and industry developments, and get a real-time snapshot of the market's sentiment
					(aka everyone's freaking out).
					Join the fun and contribute to the chaos!
				</p>
			</Modal.Body>
			<Modal.Footer>
				<div className="flex items-center justify-center">
					<BsDiscord className="mx-4 size-16" />
					<FaTwitter className="mx-4 size-16" />
					<FaBitcoin className="mx-4 size-16" />
				</div>
			</Modal.Footer>
		</Modal>
	)
}

export default memo(About)