import { Modal } from "flowbite-react";
import { useTransition, animated } from '@react-spring/web';
import { useEffect, useState , memo } from "react";
import VoteView from "./VoteView";
import Dialog from "./Dialog";
import { voteApi } from "../../api/voteApi";
import { GoInfo } from "react-icons/go";


const VotePopout = (props) => {
  let isOpen = props.isOpen ?? false;
  let openModalCb = props.openModalCb;
  let token = props.token;

  const [openModal, setOpenModal] = useState(false);
  const [dialogVisible, setIsDialogVisible] = useState(false);
  const [voteResponse, setVoteResponse] = useState({})

  const postVote = (name, voteType) => {
    voteApi.postVote(name, voteType).then((response) => {
      if (response.status === 200) {
        setVoteResponse({ message: 'vote processed successfully', type: "success" })
      }
      else if (response.status === 429) {
        setVoteResponse({ message: "vote limit exceeded for this token", type: "warning" })
      }
      else {
        setVoteResponse({ message: "vote could not be processed", type: "error" })
      }
      closeModal();
      setIsDialogVisible(true);
    })
  };

  const transitions = useTransition(openModal, {
    from: { transform: 'scale(0)' },
    enter: { transform: 'scale(1)' },
  });

  const voteCb = (tokenname, voteType) => {
    if (voteType === "bear") {
      postVote(tokenname, false);
    } else {
      postVote(tokenname, true);
    }
  }

  const closeModal = () => {
    openModalCb({open: false});
    setOpenModal(false);
  }

  useEffect(() => {
    if (isOpen)
    setOpenModal(isOpen)
  }, [isOpen])

  return (
    <div>
      {transitions((styles, item) =>
        item ? (
          <Modal dismissible show={item} onClose={closeModal}>
            <animated.div style={styles}>
              <Modal.Body>
                <div className="flex justify-between items-center w-full pb-6">
                  <span className="text-xl">What's the pulse ?</span>
                  <a href={token.coinMarketCapUrl} target="_blank" rel="noopener noreferrer">
                    <GoInfo className="text-2xl text-black hover:text-blue-500" />
                  </a>
                </div>
                <VoteView
                  token={token}
                  voteHandler={voteCb}
                />
              </Modal.Body>
            </animated.div>
          </Modal>
        ) : null
      )}
      <div style={{ position: 'fixed', right: 0, bottom: 0, zIndex: 9999 }}>
        <Dialog
          response={voteResponse}
          isDialogVisible={dialogVisible}
          dialogClose={() => setIsDialogVisible(false)}
        />
      </div>
    </div>
  );
}

export default memo(VotePopout)
