  export function generateRandomPoints(radius, minDistance, offset, count = 100) {
    const points = [];
    
    function isFarEnough(newPoint) {
      return points.every(point => {
        const distance = Math.sqrt(
          Math.pow(point.x - newPoint.x, 2) + Math.pow(point.y - newPoint.y, 2)
        );
        return distance >= minDistance;
      });
    }
  
    while (points.length < count) {
      // Generate a random angle and distance
      const angle = Math.random() * 2 * Math.PI;
      const distance = Math.sqrt(Math.random()) * radius;
      
      // Convert to Cartesian coordinates
      const x = distance * Math.cos(angle) + offset.x;
      const y = distance * Math.sin(angle) + offset.y;
      
      const newPoint = { x, y };
      
      // Check if the new point is far enough from existing points
      if (isFarEnough(newPoint)) {
        points.push(newPoint);
      }
    }
    
    return points;
  }

  export function randomPosition(radius) {
    const angle = 2 * Math.PI * Math.random();
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return { x, y };
  };