import React, { memo } from 'react';
import SearchBar from './search-bar/SearchBar';
import { GoInfo } from "react-icons/go";

const Header = (props) => {
  const handleClick = () => {
    props.aboutModal({ open: true })
  }
  return (
    <header className="w-screen h-20 bg-gradient-to-r from-[#2C3E50] via-[#5e2c73] to-[#2C3E50] relative flex justify-between items-center">
      <div className="flex items-center ml-4">
        <img src="CryptoPulse3.jpeg" alt="Logo" className="h-12 rounded-full mr-4" />
        <span className="text-white text-2xl font-semibold">Coin Pulse</span>
      </div>
      <div className="flex-1">
        <SearchBar {...props} />
      </div>
      <div className="flex items-center mr-4">
        <GoInfo onClick={handleClick} className='text-2xl text-white hover:text-blue-500' />
      </div>
    </header>
  );
}

export default memo(Header);